import React from "react";
import seaweed2 from "../../static/images/seaweed2.png";

const Seaweed2 = () => {
  return (
    <div className="Seaweed2">
      <img src={seaweed2} className="Seaweed2__img" alt="seaweed2" />
    </div>
  );
};

export default Seaweed2;
