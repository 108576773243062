import React from "react";
import seaweed from "../../static/images/seaweed.png";

const Seaweed = () => {
  return (
    <div className="Seaweed">
      <img src={seaweed} className="Seaweed__img" alt="seaweed" />
    </div>
  );
};

export default Seaweed;
