import React from "react";
import seaweed3 from "../../static/images/seaweed3.png";

const Seaweed3 = () => {
  return (
    <div className="Seaweed3">
      <img src={seaweed3} className="Seaweed3__img" alt="seaweed3" />
    </div>
  );
};

export default Seaweed3;
