import React from "react";
import seaweed4 from "../../static/images/seaweed4.png";

const Seaweed4 = () => {
  return (
    <div className="Seaweed4">
      <img src={seaweed4} className="Seaweed4__img" alt="seaweed4" />
    </div>
  );
};

export default Seaweed4;
